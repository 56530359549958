import debounce from 'lodash/debounce';
import { createStore } from '~/store/createStore';
/** 用於將 indicator 內部算得出來的「進場價」等等資訊傳遞出來 */
export const useIndicatorStore = createStore(set => ({
    update(payload) {
        indicatorStoreSetState(payload);
    },
}));
export const indicatorStoreSetState = (payload) => {
    useIndicatorStore.setState({ ...payload });
};
export const indicatorStoreSetStateDebounced = debounce((payload) => {
    useIndicatorStore.setState({ ...payload });
}, 333);
